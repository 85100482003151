import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../componentes/forms/inputs/RTextField';
import RSelect from '../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------

export default function BusqForm({ showLoader, actualizaCampo, busqueda }) {
  React.useEffect(() => {
    showLoader(false);
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1}>
        <RTextField type="text" name="texto" label="Texto*" actualizaCampo={actualizaCampo} value={busqueda.texto} md={6} />
        <RTextField type="text" name="notas" label="Anotación" actualizaCampo={actualizaCampo} value={busqueda.notas} md={6} />
      </Grid>
    </form>
  );
}
