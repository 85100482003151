import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../componentes/forms/inputs/RTextField';
import RSelect from '../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------

export default function BusqForm({ showLoader, actualizaCampo, busqueda, origenes }) {
  React.useEffect(() => {
    showLoader(false);
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1}>
        <RTextField type="text" name="texto" label="Texto Búsqueda" actualizaCampo={actualizaCampo} value={busqueda.texto} md={3} />
        <RTextField type="text" name="semantico" label="Texto" actualizaCampo={actualizaCampo} value={busqueda.semantico} md={3} />
        <RSelect name="origen" label="Origen" actualizaCampo={actualizaCampo} value={busqueda.origen} md={3}
          values={origenes}
        />
      </Grid>
    </form>
  );
}
